import React, { useState, useEffect } from "react";
import axios from "../API/axios";
import MovieModal from "./MovieModal";
import Avatar from "./Avatar";
import { providers } from "../data/providers";
import { Link } from "react-scroll";
import "./Row.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useRecoilState } from "recoil";
import { SearchBarState } from "./Searchbar";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 9,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 7,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};

const Row = ({ title, fetchUrl, isLargeRow, id }) => {
  const base_url = "https://image.tmdb.org/t/p/original/";
  const [movies, setMovies] = useState([]);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [movieSelected, setMovieSelection] = useState({});
  const [isSearching] = useRecoilState(SearchBarState);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(fetchUrl);
      setMovies(request.data.results);
      return request;
    }

    fetchData();
  }, [fetchUrl]);

  const handleClick = (movie) => {
    setModalVisibility(true);
    setMovieSelection(movie);
  };

  const searchFilter = (e) => {
    return e.original_title && e.original_title == "a";
  };

  console.log(movies);
  return (
    <section className="row">
      <h2>{title}</h2>
      <div class="slider">
        {!isSearching ? (
          <>
            <Carousel id={id} className="row__posters" responsive={responsive}>
              {movies.map((movie, index) => (
                <>
                  <a href="#player">
                    <img
                      style={{
                        position: "absolute",
                        height: "50px",
                        width: "50px",
                      }}
                      src={providers[index % 6].icon}
                    />

                    <img
                      key={movie.id}
                      onClick={() => handleClick(movie)}
                      className={` ${isLargeRow && "row__posterLarge"}`}
                      src={`${base_url}${
                        isLargeRow ? movie.poster_path : movie.backdrop_path
                      }`}
                      loading="lazy"
                      alt={movie.name}
                    />
                  </a>
                </>
              ))}
            </Carousel>
          </>
        ) : (
          <>
            <Carousel id={id} className="row__posters" responsive={responsive}>
              {movies.filter(searchFilter).map((movie, index) => (
                <>
                  <a href="#player">
                    <img
                      style={{
                        position: "absolute",
                        height: "50px",
                        width: "50px",
                      }}
                      src={providers[index % 6].icon}
                    />

                    <img
                      key={movie.id}
                      onClick={() => handleClick(movie)}
                      className={` ${isLargeRow && "row__posterLarge"}`}
                      src={`${base_url}${
                        isLargeRow ? movie.poster_path : movie.backdrop_path
                      }`}
                      loading="lazy"
                      alt={movie.name}
                    />
                  </a>
                </>
              ))}
            </Carousel>
          </>
        )}
      </div>
      {modalVisibility && (
        <MovieModal
          {...movieSelected}
          setModalVisibility={setModalVisibility}
        />
      )}
    </section>
  );
};

export default Row;
