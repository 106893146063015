import React, { useState, useEffect } from "react";
import axios from "../API/axios";
import requests from "../API/requests";
import "./Banner.css";
import { providers } from "../data/providers";
import MovieModal from "./MovieModal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const Banner = () => {
  const [movies, setMovies] = useState([]);
  const [modalVisibility, setModalVisibility] = useState(false);

  const handleModal = () => {
    setModalVisibility(!modalVisibility);
  };
  async function fetchData() {
    const request = await axios.get(requests.fetchTrending);
    return request.data.results;
  }

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(requests.fetchTrending);
      setMovies(request.data.results);
      return request;
    }
    fetchData();
  }, []);

  return (
    <>
      <Carousel responsive={responsive}>
        {movies.map((content, index) => (
          <>
            <header
              key={index}
              className="banner"
              style={{
                backgroundImage: `url(
                    "https://image.tmdb.org/t/p/original/${content?.poster_path}")`,
                backgroundPosition: "top center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed"
              }}
            >
              <div className="banner__contents">
                {/**Tittle */}
                <h1 className="banner__title">
                  {content?.title || content?.name || content?.original_name}
                </h1>
                <div className="banner__buttons"></div>
                {/**DIV > 2 BUTTONS */}

                <h1 className="banner__description">
                  {truncate(content?.overview, 150)}
                </h1>
                <div style={{ margin: "15px" }} />
                <img
                  style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                  src={providers[1].icon}
                />
                <img
                  style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                  src={providers[4].icon}
                />
                <img
                  style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                  src={providers[6].icon}
                />
              </div>
              <div className="banner--fadeBottom" />
            </header>
            {modalVisibility && (
              <MovieModal
                {...content}
                setModalVisibility={setModalVisibility}
              />
            )}
          </>
        ))}
      </Carousel>
    </>
  );
};

export default Banner;
