import React from "react";
import { CircleMenu, CircleMenuItem } from "react-circular-menu";
import { providers } from "../data/providers";


const QAMComponent = () => {
  return (
    <>
      <div style={{
        opacity: '1',
        zIndex: '1000000000',
        position: 'sticky',
        margin: '10px',
        top: '15em',
        left: '1em',
        color: 'rgb(255,255,255)',
      }}>
        <CircleMenu
        className="circleMenu"
          startAngle={-90}
          rotationAngle={180}
          itemSize={3}
          radius={9}

          rotationAngleInclusive={false}
        >

          {providers.map((provider, index) => (

            <CircleMenuItem tooltip={provider.id}>
              <a href={provider.homePage} className="hvr-grow-rotate menu-item">
                <img style={{ height: '60px', width: '60px', borderRadius: '50%' }} key={index} src={provider.icon} key={index} />
              </a>
            </CircleMenuItem>

          ))}
        </CircleMenu>
      </div>

    </>
  )


}

export default QAMComponent;