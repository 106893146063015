import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App.js";
import * as serviceWorker from "./serviceWorker";
import { RecoilRoot } from "recoil";

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.register();
