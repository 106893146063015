import React, { useState, useEffect } from "react";
import "./Nav.css";
import { Searchbar } from "./Searchbar";
import logo from "../assets/images/logo.png";
import { useRecoilState } from "recoil";
import Headroom from "react-headroom";

const Nav = () => {


  const [show, handleShow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        handleShow(true);
      } else {
        handleShow(false);
      }
    });
    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  return (

    <Headroom>
    <nav id="top" className={`nav ${show && "nav__black"}`}>
      <img alt="Lolo" src={logo} className="nav__logo" />
      <Searchbar
        handleChange={""}
        placeholder={"search library..."}
        style={{
          width: "50%",
          position: "sticky",
          right: "50px",
          borderBottom: "1px white solid",
        }}
      />
    </nav>
    </Headroom>

  );
};

export default Nav;
