import React from "react";
import { atom } from "recoil";
import { useRecoilState } from "recoil";
import "./Searchbar.css";

export const SearchBarState = atom({
  key: "searchInput",
  default: false,
});

export const SearchValueState = atom({
  key: "SearchValueState",
  default: "Cinderella",
});

const styles = {
  width: "30%",
  height: "22px",
  position: "relative",
  left: '100px',
  fontFamily: "Maven Pro",
  border: "none",
  color: "white",
  backgroundColor: "transparent",
  borderBottom: "1.5px white solid",
};

export const Searchbar = ({ placeholder, customStyles = styles }) => {
  const [isSearching, setiSSearching] = useRecoilState(SearchBarState);

  const handleSearchChange = () => {
    setiSSearching(!isSearching);
  };

  return (
    <>
      <input id="searchbar" style={styles} type="text" onChange={handleSearchChange} placeholder={placeholder} />
    </>
  );
};
