import NetflixIcon from "../assets/images/providers/netflix.jpeg";
import HuluIcon from "../assets/images/providers/hulu.jpeg";
import YoutubeIcon from "../assets/images/providers/youtube.jpeg";
import FunimationIcon from "../assets/images/providers/funimation.jpeg";
import ParamountPlusIcon from "../assets/images/providers/paramount_plus.jpeg";
import StarzPrimeVideoIcon from "../assets/images/providers/starz_prime_video.jpeg";
import HBOMaxIcon from "../assets/images/providers/hbo_max.jpeg";
import PeacockIcon from "../assets/images/providers/peacock.jpeg";
import DisneyPlusIcon from "../assets/images/providers/disney_plus.jpeg";
import LoloIcon from "../assets/images/providers/lolo.png";
export const providers = [
  { id: "Lolo", icon: LoloIcon },
  {
    id: "Netflix",
    icon: NetflixIcon,
    color: "red",
    homePage: "https://netflix.com/",
  },
  {
    id: "Disney Plus",
    icon: DisneyPlusIcon,
    color: "blue",
    homePage: "https://disneyplus.com",
  },
  {
    id: "Hulu",
    icon: HuluIcon,
    color: "green",
  },
  {
    id: "Youtube",
    icon: YoutubeIcon,
    color: "red",
  },
  {
    id: "Funimation",
    icon: FunimationIcon,
    color: "orange",
  },
  {
    id: "Paramount Plus",
    icon: ParamountPlusIcon,
    color: "yellow",
  },
  {
    id: "Starz Prime Video",
    icon: StarzPrimeVideoIcon,
    color: "Orange",
  },
  {
    id: "HBO Max",
    icon: HBOMaxIcon,
    color: "orange",
  },
  {
    id: "Peacock",
    icon: PeacockIcon,
    color: "blue",
  },
];
