import React, { useState } from "react";
import Row from "./Row";
import Banner from "./Banner";
import Nav from "./Nav";
import requests from "../API/requests";
import "./App.css";
import Helmet from "react-helmet";
import QAM from "./QAM";
import { Element } from "react-scroll";
import { RecoilRoot } from "recoil";
import { useRecoilState } from "recoil";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { BsShare, BsSearch, BsMenuApp } from "react-icons/bs";
import { BiHomeAlt } from "react-icons/bi";
import { MdFavoriteBorder } from "react-icons/md";
import { GrResources } from "react-icons/gr";
import QAMComponent from "./QAMComponent";
import { CgMiniPlayer } from "react-icons/cg";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { SiAirplayaudio, SiAirplayvideo } from "react-icons/si";
import { AiOutlineRead } from "react-icons/ai";
import { MdOutlineAccountCircle } from "react-icons/md";

const App = () => {

  return (
    <>
      <RecoilRoot>
        <Element name="top"></Element>
        <Helmet>
          <title>Library @ LoLo</title>
        </Helmet>
        <div className="app">
          <Nav />


          <QAMComponent />


          <Banner />

          <Row
            title="Last Watched"
            id="NO"
            fetchUrl={requests.fetchNetflixOriginals}
            isLargeRow
          />

          <Row
            title="Top Rated"
            id="TR"
            fetchUrl={requests.fetchTopRated}
            isLargeRow
          />
          <Row
            title="Action"
            id="AM"
            fetchUrl={requests.fetchActionMovies}
            isLargeRow
          />
          <Row
            title="Comedy"
            id="CO"
            fetchUrl={requests.fetchComedyMovies}
            isLargeRow
          />
          <Row
            title="Documentaries"
            id="DO"
            fetchUrl={requests.fetchDocumentaries}
            isLargeRow
          />
          <Row
            title="Horror"
            id="HO"
            fetchUrl={requests.fetchHorrorMovies}
            isLargeRow
          />
          <Row
            title="Romance"
            id="RO"
            fetchUrl={requests.fetchRomanceMovies}
            isLargeRow
          />
        </div>

        <SpeedDial
          ariaLabel="LoLo Menu 2"
          sx={{ position: 'sticky', bottom: 66, right: 16, color: 'rgb(255,255,255)' }}
          icon={<BsMenuApp />}
        >
          <SpeedDialAction
            key="1"
            icon={<SiAirplayaudio />}
            tooltipTitle="Podcasts"
          />
          <SpeedDialAction
            key="1"
            icon={<SiAirplayvideo />}
            tooltipTitle="Video"
          />
          <SpeedDialAction
            key="1"
            icon={<AiOutlineRead />}
            tooltipTitle="Reviews & Forums"
          />
        </SpeedDial>
        <BottomNavigation
          style={{ position: 'sticky', bottom: 0, left: 0, backgroundColor: 'rgba(0,0,0,0.7)' }}
          showLabels

        >

          <BottomNavigationAction style={{ color: 'rgb(255,255,255)', fontFamily: 'Maven Pro' }} href="#player" icon={<CgMiniPlayer />} label="Player" />
          <BottomNavigationAction style={{ color: 'rgb(255,255,255)', fontFamily: 'Maven Pro' }} icon={<MdFavoriteBorder />} label="Favorites" />
          <BottomNavigationAction style={{ color: 'rgb(255,255,255)', fontFamily: 'Maven Pro' }} icon={<BiHomeAlt />}
            label="Home" />
          <BottomNavigationAction href="#top" style={{ color: 'rgb(255,255,255)' }} icon={<BsSearch />} label="Search" />

          <BottomNavigationAction style={{ color: 'rgb(255,255,255)' }} icon={<MdOutlineAccountCircle />} label="Account" />

        </BottomNavigation>


      </RecoilRoot>
    </>
  );
};

export default App;
