import React, { useEffect, useState } from "react";
import "./MovieModal.css";
import Youtube from "react-youtube";
import movieTrailer from "movie-trailer";
import { TextInput } from "grommet";
import Avatar from "../Avatar";
import { providers } from "../../data/providers";
import Row from "../Row";
import requests from "../../API/requests";
import StickyVideo from "react-sticky-video";
import "react-sticky-video/dist/index.css";
import { Searchbar } from "../Searchbar";
import { Element } from "react-scroll";


const MovieModal = ({
  backdrop_path,
  title,
  overview,
  name,
  release_date,
  first_air_date,
  vote_average,
  setModalVisibility,
}) => {
  const base_url = "https://image.tmdb.org/t/p/original/";
  const [trailerUrl, setTrailerUrl] = useState("");
  const opts = {
    height: "400",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };
  const randomPorcentaje = () => {
    return Math.floor(Math.random() * 100);
  };
  useEffect(() => {
    if (trailerUrl) {
      setTrailerUrl("");
    } else {
      movieTrailer(title || name || "")
        .then((url) => {
          const urlParams = new URLSearchParams(new URL(url).search);
          setTrailerUrl(urlParams.get("v"));
        })
        .catch((error) => console.log(error));
    }
  }, []);

  return (
    <div id="player" className="presentation" role="presentation">
      <div className="wrapper-modal">
        <div className="modal">
          <span
            onClick={() => setModalVisibility(false)}
            className="modal-close"
          >
            CLOSE
        
          </span>
          {trailerUrl ? (
            <Element name="player">
              <Youtube videoId={trailerUrl} opts={opts} />
            </Element>
          ) : backdrop_path ? (
            <img
              className="modal__poster-img"
              src={`${base_url}${backdrop_path}`}
            />
          ): <img className="modal__poster-img"
        src={`https://d2r55xnwy6nx47.cloudfront.net/uploads/2020/09/Cosmic_Shear_2880x1620_Lede.jpg`}/>
          }
          <div className="modal__content">
            <p className="modal__details">
              <span className="modal__user-perc">
                {randomPorcentaje()}% for you
              </span>{" "}
              {release_date ? release_date : first_air_date}
            </p>
            <h2 className="modal__title">{title ? title : name}</h2>
            <div style={{ display: "flex" }}>
              <img
                style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                src={providers[3].icon}
              />{" "}
              <img
                style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                src={providers[5].icon}
              />{" "}
              <img
                style={{
                  height: "50px",
                  width: "50px",
                  width: "50px",
                  borderRadius: "50%",
                }}
                src={providers[9].icon}
              />
            </div>
            <h2 style={{ textAlign: "center", padding: "15px" }}>
            Browser
            </h2>
            <Searchbar placeholder={"find movies, providers, shows..."} />
            <Row
              title=""
              id="NO"
              fetchUrl={requests.fetchTopRated}
              isLargeRow
            />
            <p className="modal__overview">{overview}</p>
            <p className="modal__overview">Vote Average: {vote_average}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MovieModal;
